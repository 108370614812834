.descriptions {
}

.descriptionsItem {
}

.descriptionsItemWrap {
    position: relative;
    min-height: 44px;
    /*padding: 12px 12px 8px;
    border-radius: 8px;
    border: 1px solid #d2d7dd;*/
}

.pad0 .descriptionsItemWrap {
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.descriptionsItemLabel {
    /*position: absolute;
    z-index: 2;
    top: 0;
    left: 8px;*/
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.125;
    color: #929292;

    /*transform: translateY(-50%);
    /*background-color: #fff;

    padding: 0 4px !important;*/
}
.descriptionsItemLabel:after {
    content: ':';
}

.descriptionsItemContent {
    color: #000;
    font-size: 16px;
    font-weight: 600;
}

.descriptionsItemContent img {
    max-width: 100%;
}

.descriptionsItemContent--empty:after {
    content: '-';
}

.descriptionsItem--sm .descriptionsItemContent {
    font-size: 14px;
}
.ant-form-item {
    margin-bottom: 16px !important;
}

.ant-form-item:last-child {
    margin-bottom: 0 !important;
}

.ant-form-item-row {
    min-height: 36px;
}

.ant-form-item-label1 {
    position: absolute !important;
    z-index: 2;
    top: 0;
    left: 8px;
    transform: translateY(-50%);
    background-color: #fff;

    padding: 0 4px !important;
    line-height: 1.125 !important;
}

.ant-form-item-label {
    padding-bottom: 4px !important;
}

.ant-form-item-label>label {
    font-size: 13px !important;
    font-weight: 700 !important;
    line-height: 1.125;
    color: #0a033c !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
    display: none !important
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
}

.ant-form-item-control {
    position: relative;
    z-index: 1;
}

.ant-input {
    border-radius: 6px !important;
    border-color: #e9e9e9 !important;
    background: #f7f7f7 !important;
}

textarea.ant-input {
    border-radius: 8px;
}


.ant-select-selector {
    border-radius: 8px !important;
}


.ant-form-item-explain,
.ant-form-item-extra {
    margin-top: 2px;
    font-size: 12px !important;
    line-height: 1.125 !important;
}
.tableList {
    position: relative;
    padding: 4px 0 0;
    border-radius: 16px;
    background: #fff;
}

.tableList .ant-table {
    background: #fff;
    color: #181818;
    font-size: 14px;
    line-height: 1.125;
    overflow: hidden;
    border-bottom: 1px solid #e9e9e9;
}

.tableList .ant-table table {
    border-collapse: collapse;
    /*border-spacing: 0 5px;*/
}

.tableList .ant-table .ant-table-header,
.tableList .ant-table .ant-table-thead>tr>th {
    color: #888f9b;
    font-size: 11px;
    text-transform: uppercase;
}

.tableList .ant-table .ant-table-thead .ant-table-cell {
    background: transparent;
    border-bottom: 1px solid #e9e9e9;
    padding: 12px 12px;
}

.tableList .ant-table-wrapper .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before, 
.tableList .ant-table-wrapper .ant-table-thead >tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    height: 1.6em;
    background-color: #e9e9e9;
}

.tableList .ant-table-wrapper .ant-table-cell:first-child {
    padding-left: 16px !important;
}

.tableList .ant-table-wrapper .ant-table-cell:last-child {
    padding-right: 16px !important;
}

.tableList .ant-table .ant-table-tbody>tr>td {
    padding: 12px 12px;
    border-bottom: 1px solid #e9e9e9;
}

.tableList .ant-table .ant-table-tbody>tr:last-child>td {
    border-bottom: 0;
}

.tableList .ant-table-tbody>tr:hover>td {
    background: #EFFCEF;
}

.tableList .ant-table-tbody>tr.ant-table-row:hover>td,
.tableList .ant-table-tbody>tr>td.ant-table-cell-row-hover {
    background: #EFFCEF;
}

.tableList .ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: #f6fef6;
}

.tableList .ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
    background: #f6fef6;
}

.tableList .ant-table-wrapper td.ant-table-column-sort {
    background: #f6fef6;
}

.tableList .ant-table-scroll {
    overflow: auto;
    overflow-x: hidden;
}

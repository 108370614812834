.app {
    min-height: 100vh !important;
    background: #636C78 !important;
}

.ant-layout {
    background: #fff;
}

.appSider.ant-layout-sider {
    height: 100vh;
    background: #636C78;
    padding-bottom: 0;
    
}

.appSider .ant-layout-sider-children {
    overflow-y: scroll;
    overflow-x: hidden;

    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

.appSider .ant-layout-sider-trigger {
    display: none;
    position: absolute;
    z-index: 12;
    top: 77px;
    right: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #555c67;
    width: 24px !important;
    height: 24px;
    border-radius: 16px;
    color: #fff;
}

.appSider .ant-layout-sider-trigger .anticon {
    display: none;
}

.appSider .ant-layout-sider-trigger:after {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    transform: rotate(225deg) translate(-1px, 1px);
    transform-origin: 50% 50%;
}

.appSider.ant-layout-sider-collapsed .ant-layout-sider-trigger:after {
    border-color: #fff;
    transform: rotate(45deg) translate(-1px, 1px);
}

.appMain {
    height: 100vh;
    background: #f5f5f5 !important;
    border-radius: 24px 0 0 24px;
    overflow: hidden;
}

.appContent {
    height: calc(100vh - 60px);
    padding: 0 36px 36px;
    overflow-y: scroll;
}

.appContent::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

.appContent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 1em;
}

.appContent::-webkit-scrollbar-thumb {
    background-color: #d0d0d0;
    outline: none;
    border-radius: 1em;
}
.requestStatus {
    display: inline-block;
    font-size: 12px;
    line-height: 1.125;
    padding: 4px 8px;
    border-radius: 2em;
    color: rgba(0,0,0,.88);
    background: rgba(0,0,0,.02);
    border: 1px solid #d9d9d9;
}

.requestStatus.new {
    color: #08979c;
    background: #e6fffb;
    border-color: #87e8de;
}

.requestStatus.inProgress {
    color: #d46b08;
    background: #fff7e6;
    border-color: #ffd591;
}

.requestStatus.completed {
    color: #286f09;
    background: #b7eb8f;
    border-color: #4bd012;
}

.requestStatus.declined1 {
    color: rgb(104, 104, 104);
    background: rgb(219, 219, 219);
}
.logo {
    position: relative;
    margin: 24px auto 36px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo:before,
.logo:after {
    content: '';
    position: absolute;
    top: -12px;
    bottom: -12px;
    left: -12px;
    right: -12px;
    border-radius: 42px;
}

.logo:before {
    background: #555c67;
}

.logo:after {
    z-index: 2;
    border: 1px dashed rgba(255, 255, 255, .5);
}

.logoImg {
    position: relative;
    z-index: 1;
    width: 64px;
    object-fit: contain;
}

.logoTitle {
    position: relative;
    z-index: 1;
    margin-left: 8px;
    font-size: 13px;
    line-height: 1.25;
    font-weight: 700;
    text-align: left;
    color: #f2f2f2;
}


.appSider.ant-layout-sider-collapsed .logo {
    width: 48px;
    margin-bottom: 46px;
}

.appSider.ant-layout-sider-collapsed .logo:before,
.appSider.ant-layout-sider-collapsed .logo:after {
    top: -8px;
    bottom: -8px;
    left: -8px;
    right: -8px;
}

.appSider.ant-layout-sider-collapsed .logoImg {
    width: 48px;
}

.appSider.ant-layout-sider-collapsed .logoTitle {
    display: none;
}
.ant-modal-content {
    border-radius: 16px !important;
    padding: 0 !important;
}

.modalForm .ant-modal-body {
    padding: 0 36px 32px !important;
    border-radius: 16px 16px 0 0;
    background: #f3f2f2;
}

.modalForm .ant-modal-body .ant-spin-nested-loading {
    z-index: 10;
}
.modalForm .ant-modal-body .ant-spin-nested-loading .ant-spin-container::after {
    position: absolute;
    top: 0;
    bottom: -88px;
    left: -36px;
    right: -36px;
    border-radius: 16px;
    width: auto;
    height: auto;
}
.modalForm .ant-modal-body .ant-spin-nested-loading  .ant-spin-blur::after {
    opacity: 0.4;
    pointer-events: auto;
}

.modalFormHeader {
    position: relative;
    padding: 12px 24px;
    margin: 0 -36px 32px;
    border-bottom: 1px solid #d2d7dd;
    background: #fff;
    border-radius: 16px 16px 0 0;
}

.modalFormHeader__withTabs {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 8px;
}

.modalForm .ant-tabs-nav {
    margin: 0 -36px 32px !important;
    padding: 0 24px;
}


.modalFormTitle {
    color: #000;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.ant-modal-header {
    padding: 12px 24px !important;
    background: #fff;
    border-bottom: 1px solid #d2d7dd;
    border-radius: 16px 16px 0 0 !important;
}

.ant-modal-title {
    color: #000 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
    line-height: 36px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.ant-modal-footer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 12px;
    margin: 0 !important;
    padding: 12px 24px !important;
    border-bottom: 0 !important;
    border-radius: 0 0 16px 16px !important;
    border-top: 0 !important;
}

.ant-modal-footer:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-top: 1px solid #d2d7dd;
}

.ant-modal-footer .ant-dropdown-button {
    width: auto;
}

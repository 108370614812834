.avatar {
    padding: 0;
    position: relative;
    border-radius: 8px;
}

.avatarInner {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    overflow: hidden;
    border-radius: 50%;
    overflow: hidden;
}

.avatarInner img {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    border-radius: 50%;
}

.avatarInner__empty {
    text-align: center;
    color: #fff;
    background: #91c3ab;
}

.avatarInner__empty b {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    font-size: 70%;
    font-weight: 600;
}


.avatarInner__empty .anticon {
    position: absolute;
    top: .15em;
    left: 50%;
    transform: translateX(-50%);
}

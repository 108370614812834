.schemeEditor {
    position: relative;
}

.schemeEditor__scheme {
    width: 1400px;
    transform-origin: 0 0;
}

.schemeEditor__places {
    width: 1400px;
    transform-origin: 0 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

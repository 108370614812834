.fieldset {
    position: relative;
    padding: 24px;
    background: #fff;
    border-radius: 8px;
    margin-bottom: 16px;
    border: 1px solid #f0f2fa;
}

.fieldset_withHeader {
    padding-top: 0;
}

.container:last-child {
    margin-bottom: 0;
}


.fieldset__header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    padding: 14px 0 12px;
    border-bottom: 1px solid #e8e8e8;
}

.fieldset__headerMain {
    flex: 1;
}

.fieldset__actions {

}

.fieldset__title {
    text-transform: uppercase;
    letter-spacing: .4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.125;
    color: #0a033c;
}

.fieldset__content {

}
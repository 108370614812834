.cardUploadInfo {
    display: flex;
    align-items: center;
    max-width: 240px;
    color: #634200;
    padding: 4px 8px;
    border-radius: 8px;
    border: 1px solid #faad14;
    background: #f7cd7a;

    font-size: 0;
}


.cardUploadInfo__spin {
    font-size: 24px !important;
    color: #faad14;
}


.cardUploadInfo__main {
    margin-left: 8px;
    font-size: 14px;
    overflow: hidden;
}


.cardUploadInfo_file {
    overflow: hidden;
    text-overflow: ellipsis;
}
.placeStatus {
    display: inline-block;
    font-size: 12px;
    line-height: 1.125;
    padding: 4px 12px;
    border-radius: 2em;
    background: #eee;
}

.placeStatus.freeToBuy {
    background: #91cb8a;

}

.placeStatus.freeToRent {
    background: #8ac1cb;
}

.placeStatus.free {
    background: #e6ea96;
}

.placeStatus.booked {
    color: #fff;
    background: #ca6051;
}

.placeStatus.busy {
    color: #fff;
    background: #ca6051;
}
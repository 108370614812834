.clubCard {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-bottom: 68%;
    /*padding: 120px 16px 16px;*/
    margin-bottom: 32px;
}

.clubCard__face {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    overflow: hidden;
    border-radius: 16px;
    color: #fff;
    background: #000;
    background-image: linear-gradient(to right, #111, #333);
    box-shadow: 0px 1px 8px 0.2px rgba(0, 0, 0, 0.16);
}

.clubCard.red .clubCard__face {
    color: #fff;
    background: #dd0000;
    background-image: linear-gradient(to right, #bb0000, #dd0000);
}

.clubCard.gold .clubCard__face {
    color: #fff;
    background: #CCA352;
    background-image: linear-gradient(to right, #ad8a45, #CCA352);
}

.clubCard.brilliant .clubCard__face {
    color: #fff;
    background: #CBCCCB;
    background-image: linear-gradient(to right, #8e8d8d, #b8b6b6);
}

.clubCard__face--front::before {
    content: '';
    position: absolute;
    top: -13%;
    right: -16%;
    width: 70%;
    height: 0;
    padding-bottom: 48%;
    background: url(/public/img/logo3000.png) 50% 50% no-repeat;
    background-size: contain;

    filter: brightness(0) invert(1);
    opacity: .4;
}

.clubCard__title {
    position: absolute;
    left: 16px;
    bottom: 56px;
    font-family: 'PF DinDisplay Pro';
    margin-bottom: 8px;
    color: rgba(255, 255, 255, .8);
}

.clubCard__number {
    position: absolute;
    left: 16px;
    bottom: 32px;
    text-transform: uppercase;
    font-family: 'PF DinDisplay Pro';
    font-size: 22px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 1); 
}

.clubCard__name {
    position: absolute;
    left: 16px;
    right: 16px;
    bottom: 10px;
    text-transform: uppercase;
    font-family: 'PF DinDisplay Pro';
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 1); 

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.clubCard__btn {
    position: absolute;
    right: 16px;
    bottom: 29px;
}

.clubCard__barcode {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 24px;
}

.clubCard__barcode svg {
    border-radius: 4px;
}
.schemePlace {
    position: absolute;
}

.schemePlace.pointEditMode {
    z-index: 9999;
}

.schemePlace__inner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.schemePlace__svg {
    fill: rgba(100, 100, 100, 0.25);
}

.freeToBuy .schemePlace__svg {
    fill: rgba(145, 203, 138, 0.5);
}

.freeToRent .schemePlace__svg {
    fill: rgba(138, 193, 203, 0.5);
    fill: rgba(145, 203, 138, 0.5);
}

.free .schemePlace__svg {
    fill: rgba(230, 234, 150, 0.5);
    fill: rgba(145, 203, 138, 0.5);
}

.common .schemePlace__svg {
    fill: rgba(163, 189, 197, 0.75);
}

.pointEditMode .schemePlace__svg {
    stroke: #ff0000;
    stroke-width: 2px;
    fill: rgba(255, 255, 255, .5) !important;
}

.isUsed .schemePlace__svg {
    fill: rgba(138, 193, 203, .75);
    fill: rgba(255, 255, 255, .5);
}

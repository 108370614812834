.calendar {
    position: relative;
}

.calendarHeader {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 180px;
    background: #ffc;
}

.calendar .fc-header-toolbar>.fc-toolbar-chunk:nth-child(1) {
    min-width: 180px;
}
.calendar .fc-header-toolbar>.fc-toolbar-chunk:nth-child(2) {
    flex: 1;
    display: flex;
    justify-content: center;
}
.calendar .fc-header-toolbar>.fc-toolbar-chunk:nth-child(3) {
    min-width: 180px;
    display: flex;
    justify-content: flex-end;
}

.calendar .fc-header-toolbar>.fc-toolbar-chunk>div {
    display: flex;
    align-items: center;
    background: #fff;
    border-radius: 2em;
    padding: 0 8px;
}

.calendar .fc-prev-button,
.calendar .fc-next-button {
    padding: 4px !important;
    background: transparent !important;
    color: #000 !important;
    border: 0 !important;
    border-radius: 2em !important;
    box-shadow: none !important;
}

.calendar .fc-toolbar-title {
    padding: 4px 8px !important;
    font-size: 20px !important;
}

.calendar .fc .fc-timegrid-slot {
    height: 3em;
}

.calendar .fc-timegrid-event .fc-event-time {
    white-space: nowrap;
    font-size: .85em;
    font-weight: 700;
    margin-bottom: 4px;
    color: #516c5f;
}

.calendar .fc-timegrid-event .fc-event-title {
    font-size: .85em;
    line-height: 1.125;
    font-weight: 600;
    color: #fff;
}

.calendar .fc-v-event {
    padding: 4px;
    background: #8BB8A2;
    border-color: #8BB8A2;
    border-radius: 8px;
    box-shadow: none;
}

.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-next:focus {
  font-size: inherit;
  color: #000!important;
}

.ant-carousel .slick-dots {
  bottom: 0 !important;
  margin: 0 !important;
}
.ant-carousel .slick-dots li button {
  background: #000!important;
}
.ant-carousel .slick-dots li.slick-active button {
  background: #000!important;
}

.ant-carousel .slick-arrow svg {
  width: 24px;
  height: 24px;
}
.ant-carousel .slick-prev {
  left: -22px!important;;
}
.ant-carousel .slick-next {
  right: -18px!important;;
}

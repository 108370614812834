.detailLink {
    font-weight: 600;
    color: #181818;
}

.detailLink.flex, .detailValue.flex {
    display: flex;
    align-items: center;
}

.detailLink .image,
.detailLink .avatar,
.detailValue .image,
.detailValue .avatar {
    margin-right: 8px;
}

.secondary {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.25;
    color: #888f9b;
}

.nowrap {
    white-space: nowrap;
}
.pageHeaderTabsWrap {
    position: relative;
    z-index: 2;
    top: -4px;
    margin: 0 -24px;
    padding: 0 24px;
    background: #fff;
    border-bottom: 1px solid #e0e0e0;
}

.pageHeaderTabs {
    position: relative;
    top: 1px;
    user-select: none;
}

.pageHeaderTabs.ant-tabs-top>.ant-tabs-nav::before,
.pageHeaderTabs.ant-tabs-bottom>.ant-tabs-nav::before,
.pageHeaderTabs.ant-tabs-top>div>.ant-tabs-nav::before,
.pageHeaderTabs.ant-tabs-bottom>div>.ant-tabs-nav::before {
    border-bottom: 0;
}

.pageHeaderTabs .ant-tabs-nav {
    margin: 0 !important;
}

.pageHeaderTabs .ant-tabs-tab-btn {
    font-size: 12px;
    font-weight: 600;
    color: #888f9b;
}

.pageHeaderTabs .ant-tabs-tab-btn > span {
    display: inline-block;
    text-align: center;
    min-width: 20px;
    height: 20px;
    margin-left: 4px;
    padding: 0 4px;
    border-radius: 2em;
    color: #666F7C;
    background-color: transparent;
    border: 1px solid #d0d0d0;
}

.ant-tabs-tab-active .ant-tabs-tab-btn > span {
    color: #fff;
    background-color: #8bb8a2;
    border-color: #8bb8a2;
}

.pageHeaderTabs .ant-tabs-tab {
    padding: 6px 0 !important;
    border: 1px solid transparent !important;
    background: transparent !important;
    border-radius: 8px 8px 0 0 !important;
}

.pageHeaderTabs.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.pageHeaderTabs.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    background: #F3F2F2 !important;
    border: 1px solid #d2d7dd !important;
    border-bottom-color: #F3F2F2 !important;
}
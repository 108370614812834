.smartFilter {
    min-width: 300px;
    display: flex;
    align-items: center;
}

.smartFilterSearch {
    border: 1px solid #e0e0e0 !important;
    border-radius: 6px !important;
    background: #f7f7f7 !important;
}

.smartFilterSearch .ant-input-prefix {
    margin-right: 6px;
}

.smartFilterBtn {
    margin-left: 16px;
    height: 33px;
}

.smartFilterForm {
    padding: 12px;
    min-width: 400px;
    max-width: 400px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}


.schemePlace__handle {
    position: absolute;
    z-index: 9999999;
    transform: translateZ(999999);
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    margin: -9px 0 0 -9px;
    border-radius: 50%;
    border: .5px solid #000;
    background: rgba(255, 255, 0, 0.2);
    cursor: pointer;
}

.schemePlace__handle:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 8.5px;
    width: 9px;
    height: .5px;
    background: #000;
}

.schemePlace__handle:after {
    content: '';
    position: absolute;
    left: 8.5px;
    top: 4px;
    width: .5px;
    height: 9px;
    background: #000;
}

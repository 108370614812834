.smartFilterValuesWrap {
    position: sticky;
    z-index: 9;
    top: 0;
    transform: translateY(-24px);
    margin: 0 -24px 12px;
}

.smartFilterValues {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 8px 24px;
    color: #7a8895;
    font-size: 12px;
    line-height: 1;

    background: #fff;
    border-bottom: 1px solid #d2d7dd;
}

.page__witTabs .smartFilterValuesWrap {
    margin-top: 0;
}

.page__witTabs .smartFilterValues {
    margin-top: -14px;
}

.smartFilterValues__title {
    margin-right: 16px;
    font-weight: 600;
}

.smartFilterValues__content {
    display: flex;
    flex-flow: row wrap;
}

.filterValue {
    display: flex;
    align-items: center;
    margin-right: 6px;
    padding: 4px 0;
}

.filterValue__label {
    margin-right: 5px;
}

.filterValue__values {
    display: flex;
}

.filterValue__values .ant-tag {
    border-radius: 2em;
    padding: 4px 12px;
}

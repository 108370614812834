.userPanel {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 16px;
    max-width: 200px;
    height: 50px;
    cursor: pointer;
    padding: 0 12px;
    user-select: none;

    transition: all 0.3s;
    font-size: 0;
    color: #fff;
}

.userPanel__avatar {
    min-width: 32px;
    margin-right: 8px;
    background: #8BB8A2;
}

.userPanel__name {
    min-width: 0;
    font-size: 13px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.userPanel__icon {
    margin-left: 4px;
    font-size: 14px;
}

.userPanelDropdown {
    max-width: 200px !important;
    min-width: 200px !important;
}

.appSider.ant-layout-sider-collapsed .userPanel {
    display: block;
    max-width: 64px;
}

.appSider.ant-layout-sider-collapsed .userPanel__name {
    display: block;
    margin-top: 4px;
    font-size: 10px;
}

.appSider.ant-layout-sider-collapsed .userPanel__icon {
    display: none;
}
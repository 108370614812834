.topTabsWrap {
    position: relative;
    z-index: 2;
    top: -4px;
    padding: 0 24px;
    background: #fff;
    border-bottom: 1px solid #d2d7dd;
}

.topTabs {
    position: relative;
    top: 1px;
    user-select: none;
}

.topTabs.ant-tabs-top>.ant-tabs-nav::before,
.topTabs.ant-tabs-bottom>.ant-tabs-nav::before,
.topTabs.ant-tabs-top>div>.ant-tabs-nav::before,
.topTabs.ant-tabs-bottom>div>.ant-tabs-nav::before {
    border-bottom: 0;
}

.topTabs .ant-tabs-nav {
    margin: 0 !important;
}

.topTabs .ant-tabs-tab-btn {
    font-weight: 600;
    color: #666F7C;
}

.topTabs .ant-tabs-tab {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    border: 1px solid transparent !important;
    background: transparent !important;
    border-radius: 8px 8px 0 0 !important;
}

.topTabs .ant-tabs-tab:hover .ant-tabs-tab-btn {
    color: #0097EB !important;
}


.topTabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0097EB;
}

.topTabs.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.topTabs.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    background: #F3F2F2 !important;
    border: 1px solid #d2d7dd !important;
    border-bottom-color: #F3F2F2 !important;
}
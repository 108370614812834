.top {
    position: relative;
    z-index: 2;
    padding: 0 36px;
    background: #fff;
    border-bottom: 1px solid #d2d7dd;

    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    min-height: 60px;
}

.top .backBtn {
    margin-right: 12px;
}

.top__start {
    flex: 1;
    display: flex;
    align-items: center;
    padding-bottom: 6px;
}

.topTitle {
    margin-right: 16px;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    color: #0a033c;
}

.top__middle {
    flex: 1;
    display: flex;
    align-items: center;
}

.top__end {
    display: flex;
    align-items: center;
    padding-bottom: 6px;
}
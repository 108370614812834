.schemePlace__actions {
    position: absolute;
    z-index: 2;
    top: 2px;
    left: 2px;
    padding: 0;

    display: flex;
    align-items: center;
    
}

.schemePlace__action {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    /*margin-right: 2px;*/
    font-size: 14px;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid #999;
    background: #fff;
}

.editMode .editModeAction {
    background: rgb(255, 225, 0);
}

.schemePlace__action:last-child {
    margin-right: 0;
}
